/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Head from 'next/head'
import { PortableText, PortableTextComponents } from '@portabletext/react'

import { RateQuote } from '../../types'
import { Sanity } from '../../types/sanity/index'
import CallIconWhite from '../public/landing/call-icon-white.svg'

import { isCallCenterOpen } from '@/utils/call-center'
import {
  BottomCTA,
  Button,
  FeatureSection,
  Img,
  PrimaryLayout,
  Quotes,
  SEO,
} from '../components'

import { Context } from '../context'
import { buildStaticProps } from '../ssg/home'
import {
  getCtaDestination,
  getPhoneNumber,
  getSEOFromCategory,
  handlePhoneOut,
  replaceHardcodedForms,
} from '../utils'
import { homepageSerializer } from '@/constants'

export type HomeProps = {
  homepageData: Sanity.Home
}

export default function IndexPage() {
  const home: any = homepageSerializer
  const ctx = useContext<RateQuote.AppState>(Context)
  const category = ctx.user?.category
  const [phoneNumber, setPhoneNumber] = useState(getPhoneNumber(category))

  const CustomTextComponents: PortableTextComponents = React.useMemo(
    () => ({
      block: {
        normal: (props: any) => (
          <p className="font-roboto text-2xl leading-10 opacity-40 md:pr-10">
            {props.children}
          </p>
        ),
      },
      types: {
        button: (props: any) => (
          <div className="flex justify-start">
            <Link href={props.value.buttonUrl}>
              <Button classes="max-w-33 xxs:max-w-36">
                {props.value.buttonText}
              </Button>
            </Link>
          </div>
        ),
      },
    }),
    [home.leftColumn],
  )

  /**
   * Organizes the Sanity content in a way that brings the current Hero image based on the selected Insurance type.
   * `heroImages` is an Array in a specific order to visually show the hero images in Home Page.
   * Current hero images are working at dimensions of 602x400, but only the highlighted image. The side ones has its size decreased for optmization. This
   * is done by using Sanity api for image manipulation. Also the CSS that styles that HTML is tied to that mentioned dimensions,
   * so any changes may require changes there too.
   */

  const heroImages = React.useMemo(() => {
    const currentHeroImageIndex = home.heroImage.findIndex(
      (val: any) => val.imageId === ctx.user?.category,
    )
    if (currentHeroImageIndex > -1) {
      let indexBefore = currentHeroImageIndex - 1
      let indexAfter = currentHeroImageIndex + 1
      if (home.heroImage[indexBefore] && home.heroImage[indexAfter]) {
        return [
          home.heroImage[indexBefore].image,
          home.heroImage[currentHeroImageIndex].image,
          home.heroImage[indexAfter].image,
        ]
      } else {
        !home.heroImage[indexBefore]
          ? (indexBefore = home.heroImage.length - 1)
          : undefined
        !home.heroImage[indexAfter] ? (indexAfter = 0) : undefined
        return [
          home.heroImage[indexBefore].image,
          home.heroImage[currentHeroImageIndex].image,
          home.heroImage[indexAfter].image,
        ]
      }
    }

    return []
  }, [ctx.user?.category, home])

  React.useEffect(() => {
    setPhoneNumber(getPhoneNumber(ctx.user?.category))

    if (ctx.user?.category) {
      const destination = getCtaDestination(ctx.user?.category)
      replaceHardcodedForms(destination)
    }
  }, [home.leftColumn, ctx.user?.category])

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="/fonts/caveat/caveat.css" rel="stylesheet" />
      </Head>
      <a href={`tel:+${phoneNumber}`}>
        <div
          onClick={() =>
            handlePhoneOut({
              phoneNumber: phoneNumber,
              category: ctx.user?.category,
            })
          }
          className="flex h-9 w-full cursor-pointer place-content-center bg-blue-600 leading-10"
        >
          <div className="text-3xxs text-center text-white opacity-80 lg:mt-1 lg:text-base">
            Special Enrollment Period still available to enroll in health
            coverage. Call now to speak with a licensed advisor to review
            options!
          </div>
        </div>
      </a>

      <SEO
        title={'RateQuote - Your Free Insurance Comparison Platform'}
        description={
          home.seoDescription ||
          `Compare ${getSEOFromCategory(ctx.user.category)}`
        }
      />
      <PrimaryLayout category={ctx.user?.category}>
        <div className="md:force-full-width relative overflow-visible pb-6">
          <div className="px-5 sm:px-0">
            {isCallCenterOpen() && (
              <div className="xxs:w-fit mx-auto mb-8 w-full">
                <span
                  onClick={() =>
                    handlePhoneOut({
                      phoneNumber: phoneNumber,
                      category: ctx.user?.category,
                    })
                  }
                >
                  <a
                    className="text-white no-underline"
                    href={`tel:+${phoneNumber}`}
                  >
                    <div className="text-grayscale-7 font-caveat font-caveeat right-6 mr-2 ml-0 mb-10 block w-56 -rotate-12 cursor-pointer text-4xl  italic lg:mb-4 lg:-ml-28 lg:text-4xl">
                      Click To Call!
                    </div>
                  </a>
                </span>
                <a
                  className="text-white no-underline"
                  href={`tel:+${phoneNumber}`}
                >
                  <button
                    onClick={() => {
                      handlePhoneOut({
                        phoneNumber: phoneNumber,
                        category: 'health-insurance',
                      })
                    }}
                    className="rounded-lg-xl border-3.5 bg-blue-primary shadow-cta-button-blue lg:full  left-0 right-0 -bottom-10 mr-auto ml-auto flex border-white	 p-2 lg:w-96"
                  >
                    {' '}
                    <div className="flex h-full flex-col justify-center	">
                      <img
                        src={CallIconWhite.src}
                        className="mt-1 ml-1 lg:mt-3"
                      />
                    </div>
                    <div className="ml-6 pr-3 text-left text-white">
                      <div className="font-circular text-base font-medium lg:text-lg">
                        {' '}
                        Call: {phoneNumber} TTY-711
                      </div>
                      <div className="font-circular text-sm font-normal lg:text-base">
                        {' '}
                        To Speak with a Licensed Sales Agent
                      </div>
                      <div className="font-circular text-sm font-normal lg:text-base">
                        Available M-F 8am - 6pm EST
                      </div>
                    </div>
                  </button>
                </a>
              </div>
            )}
            <FeatureSection
              portableText={home.heroContent}
              align="center"
              titleClasses="max-w-5xl"
            />
          </div>

          <div className="c-hero-image-wrapper lg:c-bg-bubbles mt-7 px-3 md:px-0">
            {heroImages.length > 0 &&
              heroImages.map((imageUrl, i) => (
                <div
                  key={i}
                  className={`c-hero-image flex justify-center ${
                    i == 1 ? 'current mx-auto' : ''
                  }`}
                >
                  <Image
                    height={`${i == 1 ? '400' : '100'}`}
                    width={`${i == 1 ? '604' : '151'}`}
                    alt="Insurance Illustration image"
                    className={`${i == 1 ? 'mx-auto' : ''} block`}
                    src={imageUrl}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="c-bg-triangle c-bg-triangle-left c-bg-triangle-wrapper">
          <div
            id="middle-panel"
            className="c-bg-blur py-7-1/2 md:py-18 xs:px-4 mt-20 overflow-hidden rounded-xl border-2 border-white px-2 sm:mx-7 sm:border-4 sm:px-6 md:mt-32 lg:px-16"
          >
            <div className="c-ratequote-watermark -mx-4 sm:-mx-6 md:bg-left-bottom">
              <div
                id="as-seen-in"
                className="flex h-16 w-full flex-wrap items-start justify-evenly lg:h-11 lg:flex-nowrap lg:space-x-4"
              >
                {home.publications.map((pub: any, idx: any) => (
                  <Img
                    classes={`w-auto mt-2 mb-2 xs:mb-1 sm:mb-2 ${
                      idx > 0 && pub.name !== 'HuffPost'
                        ? 'ml-4 lg:ml-0'
                        : pub.name === 'HuffPost'
                          ? 'xxs:ml-4 lg:ml-0'
                          : 'ml-0'
                    } ${
                      pub.name !== 'The Wall Street Journal' &&
                      pub.name !== 'HuffPost'
                        ? 'h-5-3/4'
                        : 'h-4 xs:mt-3 sm:mt-2'
                    } sm:h-6 ${
                      pub.name === 'The Wall Street Journal'
                        ? 'xxs:mr-4 md:mr-0 order-second xxs:order-last lg:order-none mt-3 xxs:my-2'
                        : ''
                    }`}
                    key={pub.logo}
                    src={pub.logo}
                    alt={pub.name}
                  />
                ))}
              </div>
              <div className="grid justify-start pt-24 text-left md:grid-cols-2 md:gap-6 lg:gap-20">
                <div>
                  <div className="relative z-10">
                    {home.leftColumn.map((row: any) => (
                      <div className="px-6 pb-6" key={row._key}>
                        <PortableText
                          value={row}
                          components={CustomTextComponents}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="md:pt-2">
                  {home.rightColumn.map((row: any) => (
                    <div key={row._key} className="p-6">
                      <div className="mr-4-1/2 relative z-10 mb-3 flex flex-nowrap justify-start sm:mb-6 md:justify-start">
                        <Img
                          alt="logo"
                          src={row.icon}
                          classes="w-10-1/2 h-10-1/2 mr-5"
                          skeleton={{
                            variant: 'circle',
                          }}
                        />
                        <p className="text-blue-navy font-circular text-3xl">
                          {row.title}
                        </p>
                      </div>
                      <div className="text-blue-navy font-roboto text-xl leading-8 opacity-40 lg:pr-6">
                        {row.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="relative flex items-center justify-center">
              <div className="absolute left-0 z-10 hidden sm:block">
                <div className="bg-blue-primary h-20 w-60 rounded-full bg-opacity-60"></div>
              </div>
              <div className="relative z-20">
                <Image
                  height="200"
                  width="550"
                  alt="Insurance Illustration image"
                  className="rounded-xxl border-blue-primary block border-4"
                  src={'/static/other/call-center-group.jpg'}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
                <div className="rounded-xxl border-blue-primary absolute inset-0 border-4 blur-md"></div>
              </div>
              <div className="absolute right-0 z-10 hidden md:block">
                <div className="bg-blue-primary h-20 w-60 rounded-full bg-opacity-60"></div>
              </div>
            </div>
          </div>
        </div>
        <Quotes quotes={home.quotes} title={home.quotesHeadline} />
        <BottomCTA />
      </PrimaryLayout>
    </>
  )
}

export async function getStaticProps() {
  return await buildStaticProps()
}
